import React from "react";
import { AppContext, useAppContext } from "./hooks/auth/useAppContext";
import "./assets/libs/bootstrap-grid.min.css";
import "./scss/styles.scss";
import { Home } from "./pages/Home/Home";
import { NoMatch } from "./pages/NoMatch";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./pages/ProtectedRoute";
import { Login } from "./pages/Auth/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import ResetPwd from "./pages/Auth/ResetPwd";
import Registrazione from "./pages/Auth/Registrazione";
import { useEffect, useState } from "react";
import { Prova } from "./pages/Prova";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Loading } from "./components/Loading";
import { SuggestionsTab } from "./pages/Dashboard/components/suggestions.tab.component";
export const App = () => {
  const appContext = useAppContext();
  let lng = window.location.pathname.split("/")[1] ?? "it";
  const [currentLng, setCurrentLng] = useState(lng);
  //setto il locale in base al parametro currentLng che arriva dalla url

  const [loading, setLoading] = useState(appContext.loading);

  useEffect(() => {
    setLoading(appContext.loading);
  }, [appContext]);

  useEffect(() => {
    switch (lng) {
      case "en":
        appContext._setters._setLocale("en-GB");
        setCurrentLng(lng);
        break;
      case "it":
        appContext._setters._setLocale("it-IT");
        setCurrentLng(lng);
        break;
      case "dashboard":
        appContext._setters._setLocale("it-IT");
        setCurrentLng(lng);
        window.history.pushState(null, "", "/it/dashboard");
        break;
      default:
        return;
    }
  }, [lng, appContext._setters]);

  // controllo se la lingua è supportata considera anche il caso in cui non ci sia nessun parametro nella url
  const lngSupported = ["it", "en", "", "dashboard"];
  if (!lngSupported.includes(lng)) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path={`/*`} element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <AppContext.Provider value={appContext}>
      {loading && <Loading />}
      <ToastContainer />
      <div className="app">
        <BrowserRouter>
          <Routes>
            <Route path={`/:${currentLng}?`} element={<Login />} />
            <Route
              path={`:${currentLng}?/reset-password`}
              element={<ResetPwd />}
            />
            <Route
              path={`:${currentLng}?/account/signup`}
              element={<Registrazione />}
            />
            <Route
              path={`:${currentLng}?/dashboard`}
              element={
                <ProtectedRoute
                  component={Dashboard}
                  path={`${currentLng}/dashboard`}
                />
              }
            />

            {/* <Route
              path={`:${currentLng}?/suggestions`}
              element={
                <ProtectedRoute
                  component={SuggestionsTab}
                  path={`${currentLng}/suggestions`}
                />
              }
            /> */}
            <Route path={`:${currentLng}?/auth/login`} element={<Login />} />
            <Route path={`:${currentLng}?/prova`} element={<Prova />} />
            <Route path={`:${currentLng}?/*`} element={<NoMatch />} />
          </Routes>
        </BrowserRouter>
      </div>
    </AppContext.Provider>
  );
};
